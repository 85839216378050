import React, {useEffect, useState} from "react"
import "./Lk.css"
import { observer, inject } from 'mobx-react';
import { getHeaderStore } from "../../stores/headerStore";
import Modal from 'react-modal';
import InputMask from "react-input-mask";

import {api, signedApi} from "../../utils/api";
import axios from "axios"
import {
  useHistory,
  useParams
} from "react-router-dom"
import {getAdminStore} from "../../stores/adminStore";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import arrow from "./next.svg"

function Item(props) {


  // 0 - статистика
  // 1 - описание проекта
  // 2 - логотип
  const { logo, label, val, src, noprefix, type = 0, id, openModal, changeVal } = props
  const classNames = require("classnames")

  function onClick() {
    openModal(true)
    if (type === 1) {

      changeVal((prevState) => ({...prevState, name: label, value: val}))
    }

    if (type === 2) {
      console.log(id)
      changeVal((prevState) => ({...prevState, name: label, id, value: val}))
    }
  }

  return (
    <div className="lk__item" onClick={onClick}>
      <span className="label">{label}</span>

      {logo ?
        <img src={src ? (src.startsWith("data") ? src : `data:image/png;base64, ${src}`) : ""} alt="logo" className="img"/> :
        <span className={
          classNames("val", {
            "val-tinned": val === undefined || val === null || val === "" || val === -1
          })
        }>{val && val !== -1 ? val : 0}</span>}
    </div>
  )
}

function Lk() {

  const headerStore = getHeaderStore()
  const adminStore = getAdminStore()

  const classNames = require("classnames")

  const [ year, setYear ] = useState(0)
  const [ month, setMonth ] = useState(0)
  const [ project, setProject ] = useState({})
  const [ statsValues, setStatsValues ] = useState([])


  const [yearEditModal, setYearEditModal] = useState(false);
  const [monthEditModal, setMonthEditModal] = useState(false);
  const [cropModal, setCropModal] = useState(false);

  const [projectEditModal, setProjectEditModal] = useState(false);
  const [projectEdit, setProjectEdit] = useState({});

  const [valueEdit, setValueEdit] = useState({});
  const [valueEditModal, setValueEditModal] = useState(false);

  const [crop, setCrop] = useState({unit: "%", width: 100, height: 100});
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);

  const [scales, setScales] = useState({scaleX: 1, scaleY: 2})

  const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь",]

  function onYearChange(event) {
    setYear(parseInt(event.target.value))
  }

  function incYear(e) {
    e.preventDefault()
    setYear(year + 1)
  }

  function decYear(e) {
    e.preventDefault()
    setYear(year - 1)
  }



  function changeMonth(i) {
    setMonth(i + 1);
    loadData(i + 1, year)
  }

  function onProjectEditChange(event) {
    setProjectEdit({...projectEdit, value: event.target.value })
  }

  function onValueEditChange(event) {

    const v = event.target.value;

    if (v) {
      if (!isNaN(event.target.value)) {
        setValueEdit({...valueEdit, projectId: parseInt(id), value: parseInt(event.target.value), month, year })

      }
    } else {
      setValueEdit({...valueEdit, projectId: parseInt(id), value: "", month, year })
    }

  }

  const { id } = useParams()
  const history = useHistory()
  const now = new Date();


  async function fetchData(month, year) {
    return await api.get(`/project/getshrinked?id=${id}&month=${month}&year=${year}`)
  }

  useEffect(() => {
    setMonth(now.getMonth() + 1)
    setYear(now.getFullYear())

    fetchData(now.getMonth() + 1, now.getFullYear())
      .then((e) => {
        setProject(e.data.project)
        setStatsValues(e.data.statsValues)
        headerStore.changeEnabled(true)
        headerStore.changeBack(`/project/${id}`)
        headerStore.changeTitle(e.data.project.name)
      })
  }, [])

  function loadData(m, y) {
    fetchData(m, y)
      .then((e) => {
        setProject(e.data.project)
        setStatsValues(e.data.statsValues)
        headerStore.changeEnabled(true)
        headerStore.changeBack(`/project/${id}`)
        headerStore.changeTitle(e.data.project.name)

      })
    setMonthEditModal(false)
  }

  function saveProjectData() {
    setProjectEditModal(false);
    const newProject = {...project, [projectEdit.name.toLowerCase()]: projectEdit.value};
    newProject.founded = parseInt(newProject.founded)
    setProject(newProject);
    signedApi(adminStore.token)
      .post("/project/edit", newProject)
      .then((e) => {
        console.log(e)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.setItem("token", undefined)
          adminStore.changeAuthState(false)
          adminStore.changeToken(undefined)
          history.push("/login")
        }
      })
  }

  function saveValue() {
    setValueEditModal(false)

    const newValues = statsValues.map((e) => {
      if (e.id !== 0) {
        if (e.id === valueEdit.id) {
          e.value = valueEdit.value;
        }
      } else {
        if (e.name === valueEdit.name) {
          e.value = valueEdit.value;
        }
      }
      return e

    })
    setStatsValues(newValues)
    signedApi(adminStore.token)
      .post("/stats/changeValue", valueEdit)
      .then((e) => {
        setTimeout(() => loadData(month, year), 600)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.setItem("token", undefined)
          adminStore.changeAuthState(false)
          adminStore.changeToken(undefined)
          history.push("/login")
        }
      })
  }

  function nextModal() {
    setYearEditModal(false)
    setMonthEditModal(true)
  }

  function getDateText() {
    let monthText = month < 10 ? `0${month}` : month.toString()
    let yearText = year.toString().substring(2)
    return `${monthText}.${yearText}`
  }

  function onSelectFile (e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>{
        setSrc(reader.result)
        }
      )
      reader.readAsDataURL(e.target.files[0]);
      setFile(e.target.files[0])
    }
  }

  function onLoaded(img) {
    setScales({
      scaleX: img.naturalWidth / img.width,
      scaleY: img.naturalHeight / img.height
    })
  }

  function sendCroped() {
    let bodyFormData = new FormData();

    bodyFormData.set('id', id);
    bodyFormData.set('x', Math.floor(crop.x * scales.scaleX));
    bodyFormData.set('y', Math.floor(crop.y * scales.scaleY));
    bodyFormData.set('width', Math.floor(crop.width * scales.scaleX));
    bodyFormData.set('height', Math.floor(crop.height * scales.scaleY));
    bodyFormData.set('src', file);

     axios({
      method: 'post',
      url: 'https://dashboard.rybakovfoundation.org/api/project/changeLogo',
      data: bodyFormData,
      headers: {
        'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`,
        "Authorization": "Bearer " + adminStore.token,
      },
    }).then(() => {
       setCropModal(false)
       window.location.reload();
     });


  }

  return (
    <div className="lk">
      <span className="lk__title">Личный кабинет</span>
      <Item label="Логотип" logo noprefix src={project.logo} openModal={() => setCropModal(true)}/>
      <div className="divider">
        <span>Показатели</span>
      </div>

      <div className="choose" onClick={() => setYearEditModal(true)}>
        <span className="top">Месяц</span>
        <span className="bot">{getDateText()}</span>
      </div>

      {statsValues ? statsValues.map((e) => (
        <Item label={e.name} openModal={setValueEditModal} changeVal={setValueEdit} type={2} key={e.name} id = {e.id} val={e.value === -1 ? undefined : e.value} />
      )) : ""}

      <div className="divider">
      </div>

      <Item label="Website" openModal={setProjectEditModal} changeVal={setProjectEdit} val={project.website} type={1} />
      <Item label="Founded" openModal={setProjectEditModal} changeVal={setProjectEdit} val={project.founded} type={1} />
      <Item label="CEO" openModal={setProjectEditModal} changeVal={setProjectEdit} val={project.ceo} type={1} />
      <Item label="Info" openModal={setProjectEditModal} changeVal={setProjectEdit} val={project.info} type={1} />


      <Modal
        isOpen={projectEditModal}
        className="lk__modal modal modal-year"
        contentLabel="Example Modal"
        overlayClassName="lk__overlay"
      >
        <span className="lk__modal__title">{projectEdit.name}</span>
        <form className="lk__modal__form">
          <InputMask onChange={onProjectEditChange} maskPlaceholder="" className="lk__modal__input" type="text" value={projectEdit.value} placeholder={projectEdit.value}/>
          <div className="buttons">
            <button className="button button-cancel" type="button" onClick={() => setProjectEditModal(false)}>Отмена</button>
            <button className="button button-next" type="button" onClick={saveProjectData}>Сохранить</button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={valueEditModal}
        className="lk__modal modal modal-year"
        contentLabel="Example Modal"
        overlayClassName="lk__overlay"
      >
        <span className="lk__modal__title">Введите данные</span>
        <form className="lk__modal__form">
          <InputMask onChange={onValueEditChange}  className="lk__modal__input" type="text" value={valueEdit.value} placeholder={valueEdit.value}/>
          <div className="buttons">
            <button className="button button-cancel" type="button" onClick={() => setValueEditModal(false)}>Отмена</button>
            <button className="button button-next" type="button" onClick={saveValue}>Сохранить</button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={yearEditModal}
        className="lk__modal modal modal-year"
        contentLabel="Example Modal"
        overlayClassName="lk__overlay"
      >
        <span className="lk__modal__title">Введите год</span>
        <form className="lk__modal__form">
          <div className="year-input">
            {year === now.getFullYear() ? "" : <button className="year-button year-button-top" onClick={incYear}>
              <object data={arrow} type="image/svg+xml" >
                Have not support object
              </object>
            </button>}
            <span className="lk__modal__input" >{year}</span>
            <button className="year-button year-button-bot" onClick={decYear}>
              <object data={arrow} type="image/svg+xml" >
                Have not support object
              </object>
            </button>
          </div>
          <div className="buttons">
            <button className="button button-cancel" type="button" onClick={() => {setYearEditModal(false); setYear(now.getFullYear())}}>Отмена</button>
            <button className="button button-next" type="button" onClick={nextModal}>Далее</button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={monthEditModal}
        className="lk__modal modal modal-year"
        contentLabel="Example Modal"
        overlayClassName="lk__overlay"
      >
        <span className="lk__modal__title">Введите месяц</span>


        <div className="month-grid">
          {months.map((e, i) => (
            <button className={classNames("grid-button", {
              "grid-button-disabled": year === now.getFullYear() && i > now.getMonth()
            })} key={i} onClick={() => {
              if (year === now.getFullYear()) {
                if (i <= now.getMonth()) {
                  changeMonth(i)
                }
              } else {
                changeMonth(i)
              }

            }}>{e}</button>
          ))}

        </div>
      </Modal>

      <Modal
        isOpen={cropModal}
        className="lk__modal modal modal-img"
        contentLabel="Example Modal"
        overlayClassName="lk__overlay"
      >
        <div className="crop">
          <label htmlFor="logoLoad">
            <input id="logoLoad" type="file" accept="image/*" style={{display: "none"}} onChange={onSelectFile}/>
            Нажмите для выбора логотипа для загрузки
          </label>
          <ReactCrop onImageLoaded={onLoaded} src={src} crop={crop} onChange={newCrop => setCrop(newCrop)} />
        </div>
        <form className="lk__modal__form">
          <div className="buttons">
            <button className="button button-cancel" type="button" onClick={() => setCropModal(false)}>Отмена</button>
            <button className="button button-next" type="button" onClick={sendCroped}>Сохранить</button>
          </div>
        </form>

      </Modal>
    </div>
  )
}

Modal.setAppElement('#root');
export default inject("headerStore")(observer(Lk));