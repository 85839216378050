import React from "react"
import "./Loader.css"
import { observer, inject } from 'mobx-react';

const Loader = () => {

  return (
    <div className="lds-ellipsis">
      <span></span>
      <span></span>
      <span></span>
    </div>

  )
}


export default inject("headerStore", "statStore")(observer(Loader));