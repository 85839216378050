import React from "react"
import "./Stat.css"
import { observer, inject } from 'mobx-react';
import { getStatStore } from "../../stores/statStore";
import {formatOptions, formatWithSign} from "../../utils/numbroSettings";
import { getColoredValue } from "../../utils/utils"
import _ from "lodash";
const Stat = () => {
  const classNames = require("classnames")
  const numbro = require("numbro");
  const statStore = getStatStore()
  return (
    <div className="stats">
      {
        statStore.allStatsValues.map((e, i) => (
          <div className="inner" key={i} onClick={() => statStore.changeStatID(i)}>
            <div className="nameBlock">
            <span className=
                    {
                      classNames("name", {
                        "name-active": e.id === statStore.currentStatID
                      })
                    }
            >{e.name}</span>
            </div>
            <div className="statsBlock">
              <span className="value">{_.replace(numbro(e.sum === -1 ? 0 : e.sum).format(formatOptions), new RegExp(",", "g"), ' ')}</span>
              <span
                className={getColoredValue("delta", e.delta)}
              >
            {
              _.replace(numbro(e.delta === -1 ? 0 : e.delta).format(formatWithSign), new RegExp(",", "g"), ' ')
            }
          </span>
            </div>
          </div>
        ))
      }
    </div>
  )
}
export default inject("headerStore", "statStore")(observer(Stat));