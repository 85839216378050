import { decorate, observable, action, runInAction } from "mobx"

class AdminStore {
  userAuthed = false;
  token = "";

  changeToken(token) {
    runInAction(() => {
      this.token = token
    })
  }

  changeAuthState(val) {
    runInAction(() => {
      this.userAuthed = val
    })
  }

}

decorate(AdminStore, {
  userAuthed: observable,
  token: observable,
  changeAuthState: action,
  changeToken: action
})

let adminStore
export function getAdminStore() {
  if (!adminStore)
    adminStore = new AdminStore();
  return adminStore;
};