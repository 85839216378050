import React, { useEffect, useState } from "react"
import "./StatChart.css"
import 'mobx-react-lite/batchingForReactDom'
import { observer, inject } from 'mobx-react';
import { getStatStore } from "../../stores/statStore";
import { Line }  from 'react-chartjs-2';
import Arrow from "./arrow.svg"
import { formatOptions, formatWithSign } from "../../utils/numbroSettings";
import { getColoredValue } from "../../utils/utils"
import {useLocation} from "react-router";
import _ from "lodash";

const StatChart = () => {

  const store = getStatStore()
  const classNames = require("classnames")
  const location = useLocation()
  const numbro = require("numbro");

  const [isProject, setIsProject] = useState(false)

  useState([...store.currentDataSet])
  useEffect(() => {
    setIsProject(_.includes(location.pathname, "project"))
  }, [])

  const data = (canvas) => {
    const ctx = canvas.getContext("2d")
    const borderColor = "rgba(250, 169, 82, 1)";
    let borderColorBack = "rgba(194, 196, 198, 1)";

    if (isProject) {
      borderColorBack = "rgba(194, 196, 198, 0)"
    }

    const gradientFill = ctx.createLinearGradient(0, 35, 0, 500);

    if (isProject) {
      gradientFill.addColorStop(0, "rgba(250, 169, 82, 1)");
      gradientFill.addColorStop(0.4, "rgba(255, 231, 206, 1)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 1)");
    } else {
      gradientFill.addColorStop(0, "rgba(250, 169, 82, .9)");
      gradientFill.addColorStop(0.4, "rgba(255, 231, 206, 0.4)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0)");
    }



    const gradientFillBack = ctx.createLinearGradient(0, 35, 0, 500);
    gradientFillBack.addColorStop(0, "rgba(149, 150, 150, 0.7)");
    gradientFillBack.addColorStop(0.4, "rgba(205, 204, 203, 0.4)");
    gradientFillBack.addColorStop(1, "rgba(255, 255, 255, 0)");
    const dataSet = []

    if (isProject && store.currentBackDataSet.length !== 0) {
      dataSet.push({
        label: "Data",
        borderColor: borderColorBack,
        pointBorderColor: borderColorBack,
        pointBackgroundColor: borderColorBack,
        pointHoverBackgroundColor: borderColorBack,
        pointHoverBorderColor: borderColorBack,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 0,
        fill: true,
        backgroundColor: gradientFillBack,
        borderWidth: 2,
        lineTension: 0,
        order: 2,
        data: store.currentBackDataSet.map(e => e.value)
      })
    }

    dataSet.push({
      label: "Data2",
      borderColor: borderColor,
      pointBorderColor: borderColor,
      pointBackgroundColor: borderColor,
      pointHoverBackgroundColor: borderColor,
      pointHoverBorderColor: borderColor,
      pointBorderWidth: 10,
      pointHoverRadius: 10,
      pointHoverBorderWidth: 1,
      pointRadius: 0,
      fill: true,
      order: 0,
      backgroundColor: gradientFill,
      borderWidth: 2,
      lineTension: 0,
      data: store.currentDataSet.map(e => e.value)
    })

    return {
      labels: store.currentDataSet.map(e => `${e.month} ${e.year}`),
      datasets: dataSet
    }
  }


  return (
    <div className="chart">
      <button className={
        classNames("chart-button chart-button-left", {
          "chart-button-hidden": store.buttonsEnabled
        })
      } onClick={() => store.prevStatId()}>
        <object type="image/svg+xml" data={Arrow}  >
        </object>
      </button>
      <button className={
        classNames("chart-button chart-button-right", {
          "chart-button-hidden": store.buttonsEnabled
        })
      } onClick={() => store.nextStatId()}>
        <object type="image/svg+xml" data={Arrow}  >
        </object>
      </button>
      <div className="info-block">
        <div className="left">
          <span className="name" style={{color: "#984022"}}>{store.currentStat.name}</span>
          <span className="value">{
            _.replace(numbro(store.currentStat.sum  === -1 ? 0 : store.currentStat.sum).format(formatOptions), new RegExp(",", "g"), ' ')
          }
          </span>
          <span
            className={getColoredValue("delta", store.currentStat.delta)}
          >
            {
              _.replace(numbro(store.currentStat.delta  === -1 ? 0 : store.currentStat.delta).format(formatWithSign), new RegExp(",", "g"), ' ')
            }
          </span>
        </div>
        <div className="right">
          <span
            className={getColoredValue("delta-perc", store.currentStat.delta)}
          >
            {
              numbro(store.currentStat.percent).format({...formatWithSign, output: "percent"})
            }
          </span>
        </div>
      </div>
      <Line data={data} onElementsClick={() => store.showButtons()} options={{
        bezierCurve: false,
        legend: {
          display: false
        },
        animation: {
          easing: "easeInOutBack",
          duration: 0,
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function(value, index, values) {
                return " "
              },
              stepSize: store.stepSize
            },
            gridLines: {
              drawBorder: false,
            }


          }],
          xAxes: [{
            ticks: {
              callback: function(value, index, values) {
                return ""
              },
            },
            gridLines: {
              zeroLineColor: "transparent",
              drawTicks: false,
              display: false
            }
          }]
        }
      }}/>
      <div className="buttons">
        {["3М","6M","12M", "ALL"].map((e, i) => (
          <button className={classNames("button", {"button-active": store.currentMode === i})} key={i} onClick={() => store.changeMode(i)}>{e}</button>
        ))}

      </div>
    </div>
  )
}

export default inject("headerStore", "statStore")(observer(StatChart));