import { decorate, observable, action, runInAction, computed } from "mobx"
import _ from "lodash"

class ProjectStore {
  mainProjectList = []
  isLoading = true
  changeMainProjectList(projects) {
    runInAction(() => {
      this.mainProjectList = projects
    })
  }

  changeIsLoading(val) {
    runInAction(() => {
      this.isLoading = val;
    })
  }

  getFilteredProjectList(currentStatID) {
    return computed(() => {
      return this.mainProjectList.map(e => (
        {
          name: e.name,
          id: e.id,
          logo: e.logo,
          stat: _.first(e.statsDeltas.filter(p => p.id === currentStatID))
        }
      ))
    })
  }

}

decorate(ProjectStore, {
  mainProjectList: observable,
  isLoading: observable,
  changeMainProjectList: action,
  changeIsLoading: action,
})

let projectStore
export function getProjectStore() {
  if (!projectStore)
    projectStore = new ProjectStore();
  return projectStore;
};