import { decorate, observable, action, runInAction } from "mobx"

class HeaderStore {
  headerTitle = "";
  headerBack = "";
  headerBotLineEnabled = false;

  changeTitle(title) {
    runInAction(() => {
      this.headerTitle = title
    })
  }
  changeBack(url) {
    runInAction(() => {
      this.headerBack = url
    })
  }

  changeEnabled(val) {
    runInAction(() => {
      this.headerBotLineEnabled = val
    })
  }

}

decorate(HeaderStore, {
  headerTitle: observable,
  headerBack: observable,
  headerBotLineEnabled: observable,
  changeTitle: action,
  changeBack: action,
  changeEnabled: action
})

let headerStore
export function getHeaderStore() {
  if (!headerStore)
    headerStore = new HeaderStore();
  return headerStore;
};