import { decorate, observable, action, runInAction, computed } from "mobx"
import _ from "lodash"

class StatStore {
  currentStatID = 0
  // 0 - 3 месяца
  // 1 - 6 месяцев
  // 2 - 12 месяцев
  // 3 - все время
  currentMode = 0
  currentDataSet = []
  buttonsEnabled = true
  currentTimeout = null
  allStatsValues = []
  availableMods = [3, 6, 12, -1]
  allDataSet = []
  currentBackDataSet = []
  isLoading = true
  allData = {}
  colors = [{
    id: 0,
    textColor: "#984022",
    borderColor: "rgba(250, 169, 82, 1)",
    chartColors: ["rgba(250, 169, 82, .9)", "rgba(255, 231, 206, 0.4)", "rgba(255, 255, 255, 0)"]
  },{
    id: 1,
    textColor: "#8E6B47",
    borderColor: "rgba(194, 153, 108, 1)",
    chartColors: ["rgba(194, 153, 108, .9)", "rgba(234, 219, 202, 0.4)", "rgba(255, 255, 255, 0)"]
  },{
    id: 2,
    textColor: "#005C8A",
    borderColor: "rgba(43, 175, 211, 1)",
    chartColors: ["rgba(43, 175, 211, .9)", "rgba(75, 181, 214, 0.4)", "rgba(255, 255, 255, 0)"]
  },{
    id: 3,
    textColor: "#AE9932",
    borderColor: "rgba(251, 235, 70, 1)",
    chartColors: ["rgba(255, 232, 69, .9)", "rgba(255, 236, 121, 0.4)", "rgba(255, 255, 255, 0)"]
  },{
    id: 4,
    textColor: "#687833",
    borderColor: "rgba(174, 206, 71, 1)",
    chartColors: ["rgba(174, 206, 71, .9)", "rgba(197, 219, 129, 0.4)", "rgba(255, 255, 255, 0)"]
  },{
    id: 5,
    textColor: "#A85862",
    borderColor: "rgba(229, 136, 143, 1)",
    chartColors: ["rgba(228, 137, 142, .9)", "rgba(244, 209, 207, 0.4)", "rgba(255, 255, 255, 0)"]
  }]

  changeStatID(id) {
    runInAction(() => {
      this.currentStatID = id
      this.changeMode(this.currentMode)
    })
  }

  changeIsLoading(val) {
    runInAction(() => {
      this.isLoading = val;
    })
  }

  changeMode(mode) {
    runInAction(() => {
      if (mode > 3)
        mode = 3
      if (mode < 0)
        mode = 0

      this.currentMode = mode

      let monthCount = this.availableMods[mode]
      let dataSet = (_.first(this.allDataSet.filter(e => e.stats.id === this.currentStatID))).statsValues;
      let bigData = (_.first(this.allData.allStatValues.filter(e => e.stats.id === this.currentStatID))).statsValues;
      if (monthCount < 0) {

        let newBigData = bigData.filter(e => dataSet.find(el => el.month === e.month && el.year === e.year))
        let scaledArr = newBigData.map((e, i) => ((e.value === 0 ? 1 : e.value) / (dataSet[i].value === 0 ? 1 : dataSet[i].value)))
        const mean = _.mean(scaledArr)
        this.currentDataSet = [...dataSet]
        this.currentBackDataSet = [...bigData.map(e => ({...e, value: e.value / mean}))]
      }
      else {

        let newData = _.takeRight(dataSet, monthCount);
        let newBigData = bigData.filter(e => newData.find(el => el.month === e.month && el.year === e.year))
        let scaledArr = newBigData.map((e, i) => ((e.value === 0 ? 1 : e.value) / (newData[i].value === 0 ? 1 : newData[i].value)))
        const mean = _.mean(scaledArr)
        this.currentDataSet = [...newData]
        this.currentBackDataSet = [...newBigData.map(e => ({...e, value: e.value / mean}))]
      }
    })
  }

  changeAllData(data) {
    runInAction(() => {
      let { allStatValues } = data;
      allStatValues = [...allStatValues].map((e) => {
        e.statsValues = e.statsValues.map(el => {
          if (el.value === -1) {
            el.value = 0
          }
          return el;
        }).sort((a, b) => (a.year - b.year || a.month - b.month))

        return e

      });
      this.allData = {...data, allStatValues}
    })
  }

  changeAllDataSet(data) {
    runInAction(() => {
      this.allDataSet = [...data].map((e) => {

        e.statsValues = e.statsValues.map(el => {
          if (el.value === -1) {
            el.value = 0
          }
          return el;
        }).sort((a, b) => (a.year - b.year || a.month - b.month))

        return e

      });
    })
  }

  prevStatId() {
    runInAction(() => {
      const filtered = this.allStatsValues.filter((e) => e.id < this.currentStatID);
      if (filtered.length === 0) {
        this.currentStatID = _.last(this.allStatsValues).id
      } else {
        this.currentStatID = _.last(filtered).id;
      }
      this.changeMode(this.currentMode)
      clearTimeout(this.currentTimeout)
      this.buttonsEnabled = false;
      this.currentTimeout = setTimeout(() => {
        this.buttonsEnabled = true;
      }, 4000)


    })
  }

  nextStatId() {
    runInAction(() => {
      const filtered = this.allStatsValues.filter((e) => e.id > this.currentStatID);
      if (filtered.length === 0) {
        this.currentStatID = _.first(this.allStatsValues).id
      } else {
        this.currentStatID = _.first(filtered).id;
      }
      this.changeMode(this.currentMode)
      clearTimeout(this.currentTimeout)
      this.buttonsEnabled = false;
      this.currentTimeout = setTimeout(() => {
        this.buttonsEnabled = true;
      }, 4000)
    })
  }

  showButtons() {
    this.buttonsEnabled = false;
    this.currentTimeout = setTimeout(() => {
      this.buttonsEnabled = true;
    }, 4000)
  }

  changeAllStatsValues(values) {
    runInAction(() => {
      this.allStatsValues = [...values]
    })
  }

  get stepSize() {
    const mn = Math.min(...this.currentDataSet.map(e => e.value))
    const mx = Math.max(...this.currentDataSet.map(e => e.value))
    const diff = mx - mn;
    return Math.ceil(diff / 4)
  }

  get currentStat() {
    let stat = _.first(this.allStatsValues.filter(e => e.id === this.currentStatID));
    if (stat)
      return stat
    return {
      name: "",
      sum: 0,
      delta: 0
    };
  }

  get currentTextColor() {
    return _.first(this.colors.filter(e => e.id === this.currentStatID)).textColor;
  }

  get currentBorderColor() {
    return _.first(this.colors.filter(e => e.id === this.currentStatID)).borderColor;
  }

  getCurrentChartColor(canvas) {
    const ctx = canvas.getContext("2d")
    const colors = _.first(this.colors.filter(e => e.id === this.currentStatID)).chartColors;
    const gradientFill = ctx.createLinearGradient(0, 35, 0, 500);
    return computed(() => {

      gradientFill.addColorStop(0, colors[0]);
      gradientFill.addColorStop(0.4, colors[1]);
      gradientFill.addColorStop(1, colors[2]);
      return gradientFill;
    })
  }



}

decorate(StatStore, {
  currentStatID: observable,
  isLoading: observable,
  currentMode: observable,
  currentDataSet: observable,
  colors: observable,
  allDataSet: observable,
  allStatsValues: observable,
  currentBackDataSet: observable,
  buttonsEnabled: observable,
  allData: observable,
  changeStatID: action,
  changeMode: action,
  changeCurrentDataSet: action,
  changeAllDataSet: action,
  prevStatId: action,
  nextStatId: action,
  showButtons: action,
  changeAllStatsValues: action,
  changeIsLoading: action,
  changeAllData: action,
  stepSize: computed,
  currentStat: computed,
  currentTextColor: computed,
  currentBorderColor: computed,
})

let statStore
export function getStatStore() {
  if (!statStore)
    statStore = new StatStore();
  return statStore;
};