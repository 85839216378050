import axios from "axios";

// export const api =  axios.create({
//   baseURL: "http://localhost:15000/api",
//   responseType: "json"
// });
//j
// export const signedApi = (token, isMultiPart = false, data = {}) => {
//   return axios.create({
//     baseURL: "http://localhost:15000/api",
//     responseType: "json",
//     headers: {
//       "Authorization": "Bearer " + token,c
//       "Content-Type": isMultiPart ? `multipart/form-data; boundary=${data._boundary}` : "application/json"
//     }
//   })
// }

export const api =  axios.create({
  baseURL: "https://dashboard.rybakovfoundation.org/api",
  responseType: "json"
});

export const signedApi = (token, isMultiPart = false, data = {}) => {
  return axios.create({
    baseURL: "https://dashboard.rybakovfoundation.org/api",
    responseType: "json",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": isMultiPart ? `multipart/form-data; boundary=${data._boundary}` : "application/json"
    }
  })
}