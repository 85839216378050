import React, {useEffect, useState} from "react"
import "./Project.css"
import PropTypes from 'prop-types';
import {
  useParams
} from "react-router-dom";
import { observer, inject } from 'mobx-react';
import {api} from "../../utils/api"
import {Link} from "react-router-dom";
import Stat from "../Stat/Stat";
import {getStatStore} from "../../stores/statStore";
import {getProjectStore} from "../../stores/projectStore";
import {getHeaderStore} from "../../stores/headerStore";
import Loader from "../Loader/Loader";
import {getAdminStore} from "../../stores/adminStore";
import _ from "lodash";

const Project = (props) => {
  const statStore = getStatStore()
  const headerStore = getHeaderStore()
  const projectStore = getProjectStore()
  const adminStore = getAdminStore()
  const [website, setWebsite] = useState("");
  const [founded, setFounded] = useState("");
  const [ceo, setCeo] = useState("");
  const [info, setInfo] = useState("");

  const { id } = useParams()

  useEffect(() => {
    statStore.changeIsLoading(true)
    projectStore.changeIsLoading(true)

    async function fetchData() {
      return await api.get("/project/getallinfo")
    }

    if (statStore.allData === 0 || !statStore.allData.statsInfo) {
      fetchData().then(e => {
        statStore.changeAllData(e.data)
        api.get(`project/getinfo?id=${id}`).then((e) => {
          let web = e.data.project.website;

          if (!_.startsWith(web, "http")) {
            web = "https://" + web
          }


          setWebsite(web)
          setFounded(e.data.project.founded)
          setCeo(e.data.project.ceo)
          setInfo(e.data.project.info)
          statStore.changeAllDataSet(e.data.statsInfos)
          const data = e.data.statsInfos.map(m => {
            return (
              {
                id: m.stats.id,
                name: m.stats.name,
                sum: m.sum,
                delta: m.delta,
                percent: m.percent,

              }
            )
          })

          statStore.changeAllStatsValues(data)
          statStore.changeStatID(0)
          statStore.changeIsLoading(false)

          headerStore.changeTitle(e.data.project.name)
          headerStore.changeBack("/")
          headerStore.changeEnabled(true)

          projectStore.changeIsLoading(false)
        })
      })
    } else {
      api.get(`project/getinfo?id=${id}`).then((e) => {
        let web = e.data.project.website;

        if (!_.startsWith(web, "http")) {
          web = "https://" + web
        }


        setWebsite(web)
        setFounded(e.data.project.founded)
        setCeo(e.data.project.ceo)
        setInfo(e.data.project.info)
        statStore.changeAllDataSet(e.data.statsInfos)
        const data = e.data.statsInfos.map(m => {
          return (
            {
              id: m.stats.id,
              name: m.stats.name,
              sum: m.sum,
              delta: m.delta,
              percent: m.percent,

            }
          )
        })

        statStore.changeAllStatsValues(data)
        statStore.changeStatID(0)
        statStore.changeIsLoading(false)

        headerStore.changeTitle(e.data.project.name)
        headerStore.changeBack("/")
        headerStore.changeEnabled(true)

        projectStore.changeIsLoading(false)
      })
    }


  }, [id])

  if (projectStore.isLoading) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className="project">
        <div className="edit-line">

          {
            adminStore.userAuthed ?
            <Link className="edit-line__text" to={`/project/edit/${id}`}>Редактировать</Link> :
              <div className="line"></div>
          }

        </div>
        <div className="info">
          <div className="info__block">
            <span className="left">Website: </span>
            <a style={{"word-break": "break-all"}} className="right" href={website} target="_blank" rel="noopener noreferrer">{website}</a>
          </div>
          <div className="info__block">
            <span className="left">Founded: </span>
            <span className="right">{founded}</span>
          </div>
          <div className="info__block">
            <span className="left">CEO: </span>
            <span className="right">{ceo}</span>
          </div>
          <div className="info__block">
            <span className="left">Info: </span>
            <span className="right">{info}</span>
          </div>
        </div>
      </div>
    )
  }


}

Project.propTypes = {
  project: PropTypes.exact({
    website: PropTypes.string,
    founded: PropTypes.number,
    ceo: PropTypes.string,
    info: PropTypes.string
  })
}

export default inject("headerStore", "statStore", "adminStore", "projectStore")(observer(Project));