import React, {useEffect} from 'react';
import Header from "./Components/Header"
import ProjectList from "./Components/ProjectList/ProjectList";
import Stat from "./Components/Stat/Stat";
import StatChart from "./Components/StatChart/StatChart";
import Loader from "./Components/Loader/Loader";
import Login from "./Components/Login/Login";
import Lk from "./Components/Lk/Lk";
import {Provider, useObserver} from 'mobx-react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import {getHeaderStore} from "./stores/headerStore";
import {getStatStore} from "./stores/statStore";
import {getProjectStore} from "./stores/projectStore";
import './App.css';
import Project from "./Components/Project/Project";
import {getAdminStore} from "./stores/adminStore";

function App() {

  const headerStore = getHeaderStore()
  const statStore = getStatStore()
  const projectStore = getProjectStore()
  const adminStore = getAdminStore()

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      adminStore.changeToken(token);
      adminStore.changeAuthState(true);
    }


  }, [])

  return useObserver(() => (
    <Provider adminStore={adminStore} headerStore={headerStore} statStore={statStore} projectStore={projectStore}>
      <div className="wrap">
        <div className="wrapInner"></div>
        <BrowserRouter>
          <div className="App">
            <Header />

            <Switch>
              <Route path="/login" exact>
                {adminStore.userAuthed ? <Redirect to="/" /> : <Redirect to="/login" />}
                <Login />
              </Route>

              <Route path="/" exact>
                {statStore.isLoading ? <Loader /> : <div>
                  <StatChart />
                  <Stat />
                </div>}
                <ProjectList />
              </Route>
              <Route path="/project/:id" exact>
                {statStore.isLoading ? <Loader /> : <div>
                  <StatChart />
                  <Stat />
                </div>}
                <Project />
              </Route>
              <Route path="/project/edit/:id" exact>
                <Lk />
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
        <div className="wrapInner"></div>

      </div>
    </Provider>
  ));
}

export default App;
