import React, {useEffect} from "react"
import "./Login.css"
import { observer, inject } from 'mobx-react';
import { getHeaderStore } from "../../stores/headerStore";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {api} from "../../utils/api";
import {
  Link
} from "react-router-dom"
import {getAdminStore} from "../../stores/adminStore";
import {useHistory} from "react-router";


function Login(props) {

  const adminStore = getAdminStore()

  let history = useHistory();


  const schema = Yup.object().shape({
    password: Yup.string()
      .required("Введите пароль!"),
    email: Yup.string()
      .email("Введите почту в корректном формате!")
      .required("Введите почту!"),
  })

  return (
    <div className="login">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          api
            .post("/admin/verify", values)
            .then((e) => {
              adminStore.changeToken(e.data["access_token"])
              adminStore.changeAuthState(true);
              localStorage.setItem("token", e.data["access_token"])
              history.push("/")
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <div className="form__title">
              <h2>Администратор</h2>
              <span>Edit mode</span>
            </div>
            <div className="form__fields">
              <Field
                type="text"
                name="email"
                label="Email"
                placeholder="example@gmail.com"
              />
              <ErrorMessage name="email" component="span" className="invalid" />

              <Field
                type="password"
                name="password"
                label="Пароль"
                placeholder="*********"
              />
              <ErrorMessage name="password" component="span" className="invalid" />

            </div>
            <button type="submit">
              Войти
            </button>
          </Form>
        )}
      </Formik>

    </div>
  )
}


export default inject("headerStore")(observer(Login));