import React, {useEffect, useState} from "react"
import "./Header.css"
import Logo from "./header_logo.svg"
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import arrow from "./next.svg"
import { getHeaderStore } from "../../stores/headerStore";
import _ from "lodash"
import {
  Link, useHistory,
  useLocation, useParams
} from "react-router-dom"
import InputMask from "react-input-mask";
import Modal from "react-modal";
import {api, signedApi} from "../../utils/api";
import {getAdminStore} from "../../stores/adminStore";

function Header() {

  const store = getHeaderStore()
  const adminStore = getAdminStore()
  let location = useLocation();
  const history = useHistory()


  const [editPage, setEditPage] = useState(false)
  const [editNameModal, setEditNameModal] = useState(false)
  const [name, setName] = useState(store.headerTitle)
  const [id, setId] = useState(0)

  useEffect(() => {
    setId(parseInt(_.last(location.pathname.split("/"))))
    setName(store.headerTitle)
    setEditPage(_.includes(location.pathname, "edit"))
  }, [store, location])

  const botLine = <div className="header__bot-line">
    <div className="header__fluid">
      <object data={arrow} className="arrow" type="image/svg+xml">
        no svg support
      </object>
      <Link to={store.headerBack}>Back</Link></div>
    <span className="header__text" onClick={() => {if (editPage) {setEditNameModal(true)}}}>{store.headerTitle}</span>
    <div className="header__fluid" />
  </div>

  function onProjectEditChange(event) {
    setName(event.target.value)
  }

  function saveProjectData() {
    console.log({name, id})
    signedApi(adminStore.token)
      .post("/project/edit", {name, id: parseInt(id)})
      .then((e) => {
        console.log(e)
        store.changeTitle(name)
        setEditNameModal(false)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.setItem("token", undefined)
          adminStore.changeAuthState(false)
          adminStore.changeToken(undefined)
          history.push("/login")
        }
      })
  }

  return (
    <header className="header">
      <div className="header__top-line">
        <object type="image/svg+xml" data={Logo} className="header__logo" >
          Your browser does not support SVG
        </object>
        <div className="header__right">
          <span className="top">Impact</span>
          <span className="bot">Dashboard</span>
        </div>
      </div>
      {store.headerBotLineEnabled ? botLine : ""}

      <Modal
        isOpen={editNameModal}
        className="lk__modal modal modal-year"
        contentLabel="Example Modal"
        overlayClassName="lk__overlay"
      >
        <span className="lk__modal__title">{store.headerTitle}</span>
        <form className="lk__modal__form">
          <InputMask onChange={onProjectEditChange} maskPlaceholder="" className="lk__modal__input" type="text" value={name} placeholder={store.headerTitle}/>
          <div className="buttons">
            <button className="button button-cancel" type="button" onClick={() => setEditNameModal(false)}>Отмена</button>
            <button className="button button-next" type="button" onClick={saveProjectData}>Сохранить</button>
          </div>
        </form>
      </Modal>
    </header>
  )
}



Header.propTypes = {
  headerTitle: PropTypes.string,
  headerBack: PropTypes.string
}

export default inject("headerStore")(observer(Header));