import React, {useEffect} from "react"
import "./ProjectList.css"
import PropTypes from 'prop-types';
import 'mobx-react-lite/batchingForReactDom'
import { observer, inject } from 'mobx-react';
import {formatOptions, formatWithSign} from "../../utils/numbroSettings";
import { getColoredValue } from "../../utils/utils"
import {getProjectStore} from "../../stores/projectStore";
import {getStatStore} from "../../stores/statStore";
import {api} from "../../utils/api"
import {
  Link
} from "react-router-dom";
import {getHeaderStore} from "../../stores/headerStore";
import _ from "lodash";


const ProjectList = () => {
  const numbro = require("numbro");
  const statStore = getStatStore()
  const projectStore = getProjectStore()
  const headerStore = getHeaderStore()

  useEffect(() => {
    statStore.changeIsLoading(true)
    headerStore.changeEnabled(false)

    async function fetchData() {
      return await api.get("/project/getallinfo")
    }

    if (!statStore.allData.statsInfo) {
      fetchData().then(e => {
        statStore.changeAllStatsValues(e.data.statsInfo)
        statStore.changeAllDataSet(e.data.allStatValues)
        statStore.changeAllData(e.data)
        projectStore.changeMainProjectList(e.data.projectDeltas)
        statStore.changeMode(3)
        statStore.changeIsLoading(false)
      })
    } else {
      statStore.changeAllStatsValues(statStore.allData.statsInfo)
      statStore.changeAllDataSet(statStore.allData.allStatValues)
      projectStore.changeMainProjectList(statStore.allData.projectDeltas)
      statStore.changeMode(3)
      statStore.changeIsLoading(false)
    }



  }, [])


  if (!statStore.isLoading) {
    return (

      <div className="projectsList">
        <div className="line"></div>
        <div className="inner">
          {
            projectStore.getFilteredProjectList(statStore.currentStatID).get().map((e) => {

              return (
                <div className="item" key={e.id}>
                  <Link className="link" to={`/project/${e.id}`}>
                  <img className="logo" src={`data:image/png;base64, ${e.logo}`} alt=""/>
                  <div className="text">
                    <span className="name">{e.name}</span>

                    <span
                      className={getColoredValue("delta", e.stat.delta)}
                    >
                {
                  _.replace(numbro(e.stat.delta === -1 ? 0 : e.stat.delta).format(formatOptions), new RegExp(",", "g"), ' ')
                }
              </span>
                  </div>
                  </Link>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }


}

ProjectList.propTypes = {
  project: PropTypes.exact({
    website: PropTypes.string,
    founded: PropTypes.number,
    ceo: PropTypes.string,
    info: PropTypes.string
  })
}

export default inject("headerStore", "statStore", "projectStore")(observer(ProjectList));